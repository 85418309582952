















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'LoadingIndicator',
})
export class LoadingIndicator extends Vue {
  @Prop({ required: false, default: 'Loading' })
  protected readonly text!: string;

  @Prop({ required: false, default: false })
  protected readonly hideText!: boolean;

  @Prop({ required: false, default: 'primary' })
  protected readonly spinnerColor!: string;

  @Prop({ required: false, default: 24 })
  protected readonly spinnerSize!: string;

  @Prop({ required: false, default: 2 })
  protected readonly spinnerWidth!: string;
}

export default LoadingIndicator;
