














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { LazyPromise } from '@/lib/LazyPromise';
import { CommentPromptForm } from './CommentPromptForm.vue';

export type CommentCreateProps = {
  /** Text at the top of the dialog window/modal */
  title?: string;
  /** Text describing the context or purpose of the dialog window/modal */
  subtitle?: string;
  /** Text used on the cancellation button */
  cancelText?: string;
  /** Text used on the confirmation button */
  confirmText?: string;
  /** Force users to submit a comment */
  required?: boolean;
};

export type CommentCreateResponse = {
  comment: string;
};

let dialogRenderCount = 0;

@Component({
  name: 'CommentPromptDialog',
  components: {
    CommentPromptForm,
  },
})
export class CommentPromptDialog extends Vue {
  @Prop({ required: false, default: 'Add a Comment' })
  protected readonly title!: string;

  @Prop({ required: false, default: 'Include details regarding your changes before submitting.' })
  protected readonly subtitle!: string;

  /**
   * Confirmation text for dialog-form's affirmative action
   */
  @Prop({ required: false, default: 'Continue' })
  protected readonly confirmText!: string;

  /**
   * Cancellation text for dialog-form's negative action
   */
  @Prop({ required: false, default: 'Cancel' })
  protected readonly cancelText!: string;

  @Prop({ required: false, default: false })
  protected readonly required!: boolean;

  /**
   * Promise for resolving a value through asynchronously
   */
  @Prop({ required: true, default: undefined })
  private readonly promise!: LazyPromise<any>;

  /**
   * Show dialog modal
   */
  protected dialog = true;

  /**
   * Dialog form data
   */
  protected form = {
    comment: '',
  };

  /**
   * Return a helper function
   */
  public static attach(propsData: CommentCreateProps = {}): () => Promise<CommentCreateResponse> {
    return async function(this: Vue) {
      const lazyPromise = new LazyPromise();
      const instance = new CommentPromptDialog({
        parent: this,
        propsData: {
          confirmText: 'Confirm',
          cancelText: 'Cancel',
          ...propsData,
          promise: lazyPromise,
        },
      });

      // Prevent multiple copies of dialog from being rendered
      if (dialogRenderCount > 0) {
        lazyPromise.reject();
        return lazyPromise;
      }

      dialogRenderCount = 1;
      instance.$mount();
      return await lazyPromise;
    };
  }

  protected confirm() {
    if (this.required && !this.form.comment) return;
    this.dialog = false;
    this.promise.resolve(this.form);
    this.$destroy();
    dialogRenderCount = 0;
  }

  protected cancel() {
    this.dialog = false;
    this.promise.reject();
    this.$destroy();
    dialogRenderCount = 0;
  }
}

export default CommentPromptDialog;
