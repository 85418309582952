





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrderStatus } from '@/lib/enum';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'OrderStatusIndicator',
})
export default class OrderStatusIndicator extends Vue {
  /**
   * Status used to render status indicator
   */
  @Prop({ required: true, default: OrderStatus.Submitted })
  protected status!: OrderStatus;

  /**
   * Get class-name of the status indicator based on status
   */
  protected getClassObject(status: OrderStatus) {
    return {
      'gray--text text--darken-2': !status,
      'yellow--text text--darken-2': status ===  OrderStatus.Submitted,
      'cyan--text': status === OrderStatus.Reviewed,
      'green--text text--darken-1': status === OrderStatus.Approved,
      'red--text text--darken-2': status === OrderStatus.Denied,
      'green--text text--darken-3': status === OrderStatus.Complete || status === OrderStatus.Shipped,
    };
  }

  /**
   * Get the icon related to the Order's status
   */
  protected getIcon(status: OrderStatus) {
    switch (status) {
      case OrderStatus.Submitted: return 'mdi-alpha-s-circle';
      case OrderStatus.Reviewed: return 'mdi-alpha-r-circle';
      case OrderStatus.Approved: return 'mdi-check-circle';
      case OrderStatus.Denied: return 'mdi-close-circle';
      case OrderStatus.Complete: return 'mdi-alpha-c-circle';
      case OrderStatus.Shipped: return 'mdi-star-circle';
      default: return 'mdi-checkbox-blank-circle';
    }
  }

  /**
   * Format status label for the status indicator's tooltip
   */
  protected formatStatus(status: string) {
    if (!status) {
      return Utility.titleCase(OrderStatus.Submitted);
    }
    if (status === OrderStatus.Reviewed) {
      return Utility.titleCase('reviewed');
    }
    return Utility.titleCase(status);
  }
}
