















































































import { OrderStatus } from '@/lib/enum';
import { Order } from '@/models/internal';
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import SearchFieldTooltip from '@/components/Tooltip/SearchFieldTooltip.vue';

type OrdersListControlsAction = {
  label: string;
  icon: string;
  value: string;
};

@Component({
  name: 'OrdersListControls',
  components: {
    SearchFieldTooltip,
  },
})
export class OrdersListControls extends Vue {
  @Prop({ required: false, default: () => [] })
  protected readonly actions!: OrdersListControlsAction[];

  @PropSync('search')
  protected searchText!: string;

  @PropSync('selected', { required: false, default: () => [] })
  protected syncedSelected!: Order[];

  @PropSync('onlyShowRequireApproval', { required: true })
  protected syncedOnlyShowRequireApproval!: boolean;

  /**
   * In the Orders visible in the table, select all that have
   * a `submitted` status, meaning they're awaiting to be reviewed
   */
  protected selectSubmitted(orders: Order[]) {
    this.syncedSelected = orders.filter((order) => order.status === OrderStatus.Submitted);
  }
}

export default OrdersListControls;
