

























































































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'OrdersFormControls',
})
export default class OrdersFormControls extends Vue {
  @Prop({ required: false, default: false })
  protected loading!: boolean;

  @Prop({ required: false, default: false })
  private showCreate!: boolean;

  @Prop({ required: false, default: false })
  private showUpdateStatus!: boolean;

  @Prop({ required: false, default: false })
  private showRead!: boolean;

  @Prop({ required: false, default: false })
  private showUpdate!: boolean;

  protected isOpen = false;

  /**
   * There are available actions for these controls
   */
  protected get hasActions() {
    return this.showCreate
      || this.showUpdateStatus
      || this.showRead
      || this.showUpdate;
  }

  protected save(): void {
    this.$emit('save', undefined);
  }

  protected approve(): void {
    this.$emit('approve', undefined);
  }

  protected deny(): void {
    this.$emit('deny', undefined);
  }
}
