



















import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoListItem from '@/components/InfoList/InfoListItem.vue';
import {
  InfoListItem as InfoListItemType,
  InfoListSection as InfoListSectionType,
} from '@/lib/types/InfoList.type';
import { IModelView } from '@/lib/interfaces';
import { Fulfillment, Order, prepareData } from '@/models/internal';
import { Maybe } from '@/lib/types';
import { FulfillmentService, UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import Utility from '@/tools/Utility';
import { ServiceLevelAgreement } from '@/lib/ServiceLevelAgreement';
import { InfoListPanel } from '@/components/InfoList/InfoListPanel.vue';

@Component({
  name: 'FulfillmentView',
  components: {
    InfoListItem,
    InfoListPanel,
  },
})
export class FulfillmentView extends Vue implements IModelView<Fulfillment> {
  @Prop({ required: false, default: null })
  protected readonly fulfillment!: Fulfillment | null;

  @Prop({ required: false, default: null })
  protected readonly id!: string | number | null;

  @Prop({ required: false, default: null })
  protected readonly order!: Order | null;

  public data: Maybe<Fulfillment> = null;

  protected serviceLevelAgreement!: ServiceLevelAgreement;

  protected readonly logger = new Logger({ context: 'FulfillmentView' });

  protected readonly fulfillmentService = FulfillmentService.getInstance();

  protected readonly userService = UserService.getInstance();

  protected loading = false;

  public created() {
    this.init();
  }

  public async init(): Promise<void> {
    this.loading = true;

    const { defaults } = await Utility.fetchConfig();
    this.serviceLevelAgreement = new ServiceLevelAgreement(defaults.service_level_agreements);

    if (!this.id && this.fulfillment) {
      this.data = this.fulfillment;
      return;
    }

    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  public async fetchData(): Promise<Fulfillment> {
    const data = this.fulfillmentService.api.findOne({
      id: this.id,
      order_id: this.order?.id,
      authentication_token: this.userService.getActiveToken(),
    });
    const preparedData =  prepareData(data, FulfillmentService.mapData);
    const fulfillment = new Fulfillment(preparedData);
    return fulfillment;
  }

  /**
   * Section for rendering a list of InfoListItem components.
   */
  protected get section(): InfoListSectionType | null {
    if (!this.data) return null;

    const { status, fulfillment_detail, fulfillment_reference } = this.data;
    const items: InfoListItemType[] = [
      {
        label: 'Fulfillment Reference',
        value: fulfillment_reference,
      },
      {
        label: 'Carrier',
        value: fulfillment_detail.carrier,
      },
      {
        label: 'Credit Note',
        value: fulfillment_detail.credit_note,
      },
      {
        label: 'Credit',
        value: fulfillment_detail.credit,
      },
      {
        label: 'Currency',
        value: fulfillment_detail.currency,
      },
      {
        label: 'Custom',
        value: fulfillment_detail.custom,
      },
      {
        label: 'Discount Note',
        value: fulfillment_detail.discount_note,
      },
      {
        label: 'Discount',
        value: fulfillment_detail.discount,
      },
      // {
      //   label: 'End Date',
      //   value: (fulfillment_detail.end_date)
      //     ? Utility.formatDate(fulfillment_detail.end_date, true)
      //     : null,
      // },
      {
        label: 'External Customer Id',
        value: fulfillment_detail.external_customer_id,
      },
      {
        label: 'Freight Service',
        value: fulfillment_detail.freight_service,
      },
      {
        label: 'Freight Tax',
        value: fulfillment_detail.freight_tax,
      },
      // {
      //   label: 'Order Date',
      //   value: (fulfillment_detail.order_date)
      //     ? Utility.formatDate(fulfillment_detail.order_date, true)
      //     : null,
      // },
      {
        label: 'Payments',
        value: fulfillment_detail.payments,
      },
      // {
      //   label: 'Ship Date',
      //   value: (fulfillment_detail.ship_date)
      //     ? Utility.formatDate(fulfillment_detail.ship_date, true)
      //     : null,
      // },
      // {
      //   label: 'Start Date',
      //   value: (fulfillment_detail.start_date)
      //     ? Utility.formatDate(fulfillment_detail.start_date, true)
      //     : null,
      // },
      {
        label: 'Terms',
        value: fulfillment_detail.terms,
      },
      {
        label: 'Tracking Number',
        value: (fulfillment_detail.tracking_number)
          ? String(fulfillment_detail.tracking_number).split(',').map((num: string) => num.trim()).toString()
          : null,
      },
    ];

    if (status) {
      const estimatedDelivery = this.serviceLevelAgreement.estimateDelivery(
        this.data.created_at,
        fulfillment_detail.priority,
      );

      items.unshift({
        label: 'Estimated Delivery',
        value: '≈ ' + Utility.formatDate(estimatedDelivery),
      });

      items.unshift({
        label: 'Status',
        value: Utility.titleCase(status),
      });
    }

    return {
      items,
      label: fulfillment_reference,
    };
  }
}

export default FulfillmentView;
