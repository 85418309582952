

















import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { Order } from '@/models/internal';
import FormBase from '@/components/FormBase/FormBase.vue';
import type { FormBaseSchema } from '@/lib/types';
import { IFormValidator } from '@/lib/interfaces';

@Component({
  name: 'OrdersForm',
  components: {
    FormBase,
  },
})
export default class OrdersForm extends Vue implements IFormValidator {
  @Ref('form')
  public readonly formRef!: any;

  @Prop({ required: true })
  public readonly model!: Order;

  @Prop({ required: true })
  protected readonly schema!: FormBaseSchema;

  @Prop({ required: false, default: () => ({ cols: 12, md: 6 }) })
  protected readonly col!: any;

  @Prop({ required: false, default: false })
  protected readonly disabled!: boolean;

  public valid = true;

  public validate(): boolean {
    return this.formRef.validate();
  }

  public resetValidation(): void {
    this.valid = true;
    return this.formRef.resetValidation();
  }

  public reset(): void {
    this.valid = true;
    this.formRef.reset();
  }

  /**
   * Validate form then emit save event when button is clicked
   */
  public save(): boolean {
    this.valid = this.validate();
    if (!this.valid) {
      this.resetValidation();
      this.$emit('error', this.model);
      return false;
    }
    this.$emit('save', this.model);
    return true;
  }
}
