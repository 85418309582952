



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import OrdersTimelineItem, { IOrdersTimelineItem } from './OrdersTimelineItem.vue';
import { Order } from '@/models/internal';
import { OrderStatus } from '@/lib/enum/Status.enum';

@Component({
  name: 'OrdersTimeline',
  components: {
    OrdersTimelineItem,
  },
})
export default class OrdersTimeline extends Vue {
  /**
   * Is timeline drawer open
   */
  @Prop({ required: true })
  protected value!: boolean;

  /**
   * Order to display timeline for
   */
  @Prop({ required: true })
  private order!: Order;

  protected readonly OrderStatus = OrderStatus;

  /**
   * Timeline items
   */
  protected get timelineItems(): IOrdersTimelineItem[] {
    return this.order.previous_form_states.map((state) => ({
      action: state.action,
      approvals: state.form_data.approvals,
      date: state.form_data.order_detail.date,
      note: state.form_data.notes,
      order_items: state.form_data.order_items,
    }));
  }

  protected onInput(event: Event) {
    return this.$emit('input', event);
  }

  protected onClose() {
    return this.$emit('input', false);
  }
}
