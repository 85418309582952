









































import { OrderType } from '@/lib/enum';
import Utility from '@/tools/Utility';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'OrderTypeIndicator',
})
export default class OrderTypeIndicator extends Vue {
  @Prop({ required: true, default: '' })
  protected readonly orderType!: OrderType;

  @Prop({ required: false, default: '' })
  protected readonly label!: string;

  /**
   * Format type label for the type indicator's tooltip
   */
  protected formatType(type: string) {
    if (!type) {
      return Utility.titleCase('N/A');
    }
    return Utility.titleCase(type);
  }

  /**
   * Get the icon related to the Order's orderType
   */
  protected getIcon(type: string) {
    if (!type) return 'mdi-check-circle';
    switch (type.toLocaleLowerCase()) {
      case OrderType.Standard:
        return 'mdi-truck';
      case OrderType.Rush:
        return 'mdi-truck-delivery';
      case OrderType.Emergency:
        return 'mdi-truck-fast';
      default:
        return 'mdi-border-none-variant';
    }
  }

  /**
   * Get the icon related to the Order's orderType
   */
  protected getColor(type: string) {
    switch (type.toLocaleLowerCase()) {
      case OrderType.Rush:
        return 'warning darken-1';
      case OrderType.Emergency:
        return 'error darken-1';
      default:
        return '';
    }
  }
}
