












































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { ICollectionView } from '@/lib/interfaces';
import { Comment } from '@/models/internal';
import { CommentService, UserService } from '@/services';
import CommentList from '@/components/Comments/components/CommentList.vue';
import CommentCreateField from '@/components/Comments/components/CommentCreateField.vue';
import { Logger } from '@/tools/Logger';
import { Loading } from '@/lib/types';

@Component({
  name: 'CommentsView',
  components: {
    CommentList,
    CommentCreateField,
  },
})
export default class CommentsView extends Vue implements ICollectionView<Comment> {
  @Ref('root')
  private readonly rootRef!: Element;

  /**
   * Title of the Comments View displayed at top of the card
   */
  @Prop({ required: false, default: 'Comments' })
  private readonly title!: string;

  /**
   * Subtitle displayed at top of the card, underneath the title
   */
  @Prop({ required: false, default: '' })
  private readonly subtitle!: string;

  /**
   * ID of the Parent
   */
  @Prop({ required: true, default: 0 })
  private readonly parentId!: CommentsViewProps['parentId'];

  /**
   * Entity type of the Parent
   */
  @Prop({ required: true, default: '' })
  private readonly parentEntity!: CommentsViewProps['parentEntity'];

  public data: Comment[] = [];

  public loading: Loading = {
    submit: false,
  };

  /**
   * Comment Service
   */
  private readonly commentService: CommentService = CommentService.getInstance();

  /**
   * User Service
   */
  private readonly userService: UserService = UserService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'CommentsView' });

  public created() {
    if (!this.parentId || !this.parentEntity) {
      throw Error('Missing props `parentId` and `parentEntity`');
    }
    this.init();
  }

  public async init(): Promise<void> {
    this.data = await this.fetchData();
  }

  public async fetchData(): Promise<Comment[]> {
    const token = this.userService.getActiveToken();
    const { data } = await this.commentService.api.find({
      id: this.parentId,
      entity: this.parentEntity,
      entity_id: this.parentId,
      authentication_token: token,
    });
    return (data.comments)
      ? data.comments.map((comment: any) => new Comment(comment)).reverse()
      : [];
  }

  protected async submit(comment: string) {
    this.loading.submit = true;
    const token = this.userService.getActiveToken();
    try {
      const { data: response } = await this.commentService.api.create({
        authentication_token: token,
        entity: this.parentEntity,
        entity_id: this.parentId,
        comment: {
          body: comment,
        },
      });
      const newComment = new Comment(response.comment);
      this.data.push(newComment);
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.submit = false;
    }
  }
}

/**
 * Props used in rendering a CommentsView component
 */
type CommentsViewProps = {
  /**
   * Rest
   */
  [rest: string]: any;
  /**
   * ID of the parent
   */
  parentId: number;
  /**
   * Entity type of parent
   */
  parentEntity: string;
  /**
   * Title of the CommentsView card
   */
  title?: string;
  /**
   * Subtitle placed underneath card title
   */
  subtitle?: string;
};

/**
 * Helper function for rendering a CommentsView for a particular ModelView
 */
export function showComments(
  this: Vue,
  el: Element,
  propsData: CommentsViewProps,
): void {
  const instance = new CommentsView({
    parent: this,
    propsData,
  });
  instance.$mount();
  el.appendChild(instance.$el);
}
