































import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoListItem from '@/components/InfoList/InfoListItem.vue';
import type { InfoListSection as InfoListSectionType } from '@/lib/types/InfoList.type';

@Component({
  name: 'InfoListPanel',
  components: {
    InfoListItem,
  },
})
export class InfoListPanel extends Vue {
  @Prop({ required: true })
  protected readonly section!: InfoListSectionType;
}

export default InfoListPanel;
