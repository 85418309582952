



















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrderApprovals } from '@/models/Order';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import { Utility } from '@/tools/Utility';
import { OrderItem } from '@/models/internal';

/**
 * Contract for what the OrdersTimelineItem component expects
 */
export interface IOrdersTimelineItem {
  action: string | string[];
  approvals: OrderApprovals;
  date: string;
  note: string;
  order_items: OrderItem[];
}

@Component({
  name: 'OrdersTimelineItem',
  components: {
    ApprovalStatusIndicator,
  },
})
export default class OrdersTimelineItem extends Vue {
  @Prop({ required: false, default: () => ({}) })
  protected approval!: any;

  @Prop({ required: true })
  private timelineItem!: IOrdersTimelineItem;

  /**
   * Item is expanded
   */
  protected active = false;

  protected get actions() {
    if (Array.isArray(this.timelineItem.action))
      return this.timelineItem.action;
    return [this.timelineItem.action];
  }

  protected get date() {
    if (!this.timelineItem.date) return null;
    return {
      label: 'Date',
      value: Utility.formatDate(this.timelineItem.date, true),
      icon: 'mdi-calendar',
    };
  }

  protected get comment() {
    if (!this.timelineItem.note) return null;
    return {
      label: 'Comment',
      value: this.timelineItem.note, // note == comment in this sense
      icon: 'mdi-clipboard',
    };
  }

  protected get approvals() {
    if (!this.timelineItem.approvals) return null;
    return {
      label: 'Approvals',
      value: Object.entries(this.timelineItem.approvals),
      icon: 'mdi-order-bool-descending',
    };
  }

  protected getIcon(action: string) {
    switch (action) {
      case 'create':
        return 'mdi-timeline-plus';
      case 'update':
        return 'mdi-timeline-text';
      case 'review':
      case 'approve':
      case 'auto_approve':
        return 'mdi-timeline-check';
      case 'deny':
        return 'mdi-timeline-remove';
      case 'finalize':
        return 'mdi-timeline-alert';
      case 'send_part_a':
      case 'send_part_b':
      case 'send_part_c':
        return 'mdi-timeline-check-outline';
      case 'reject_part_a':
      case 'reject_part_b':
      case 'reject_part_c':
        return 'mdi-timeline-remove-outline';
      default:
        return 'mdi-timeline';
    }
  }

  protected getAction(action: string) {
    switch (action) {
      case 'create':
        return 'Created';
      case 'update':
        return 'Updated';
      case 'review':
        return 'Reviewed';
      case 'auto_approve':
        return 'Auto-Approved by System';
      case 'approve':
        return 'Approved';
      case 'deny':
        return 'Denied';
      case 'finalize':
        return 'Finalized';
      case 'send_part_a':
        return 'Sent PPE Order to Warehouse';
      case 'send_part_b':
        return 'Sent Swab Order to Warehouse';
      case 'send_part_c':
        return 'Sent N95 Order to Warehouse';
      case 'reject_part_a':
        return 'Rejected PPE Supplies Order';
      case 'reject_part_b':
        return 'Rejected Viral Swab Order';
      case 'reject_part_c':
        return 'Rejected N95 Masks Order';
      default:
        throw Error('Unknown action');
    }
  }

  protected getSection(section: string) {
    switch (section) {
      case 'info':
        return 'Site Info';
      case 'part_a':
        return 'PPE Status';
      case 'part_b':
        return 'Swab Status';
      case 'part_c':
        return 'N95 Status';
      default:
        throw Error('Unknown section');
    }
  }

  /**
   * Check whether to show a given section in approvals.
   * Site Info is always shown, whereas parts are only shown if there are items present.
   */
  protected showSection(section: string) {
    if (section === 'info') return true;

    const section_total = this.timelineItem.order_items
      .filter((item) => item.form_section === section)
      ?.reduce((acc, item) => acc + item.quantity, 0);
    return section_total > 0;
  }
}
