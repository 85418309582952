





















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Comment as CommentModel } from '@/models/internal';
import Comment from '@/components/Comments/components/Comment.vue';

@Component({
  name: 'CommentList',
  components: {
    Comment,
  },
})
export default class CommentList extends Vue {
  @Prop({ required: true, default: () => ([]) })
  protected readonly comments!: CommentModel[];
}
