
































import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { Comment as CommentModel } from '@/models/internal';

@Component({
  name: 'Comment',
})
export default class Comment extends Vue {
  @Prop({ required: true, default: () => ({}) })
  private readonly comment!: CommentModel;

  private static readonly COMMENT_DATE_FORMAT = 'YYYY-MM-DD';

  private static readonly COMMENT_TIME_FORMAT = 'h:mm a';

  /**
   * Comment's Author
   */
  protected get author() {
    const author = this.comment.author;
    if (!author) return {};
    return author;
  }

  /**
   * Formatted date string
   */
  protected get date() {
    return moment(this.comment.created_at).format(Comment.COMMENT_DATE_FORMAT);
  }

  /**
   * Formatted date string
   */
  protected get time() {
    return moment(this.comment.created_at).format(Comment.COMMENT_TIME_FORMAT);
  }
}
