












































































import { LazyPromise } from '@/lib/LazyPromise';
import type { VAutoCompleteItem, VTextField } from '@/lib/types/Vuetify.type';
import { Order } from '@/models/internal';
import { AuthService } from '@/services/AuthService';
import { CreateElement } from 'vue';
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { CommentPromptForm } from '@/components/Comments/components/CommentPromptForm.vue';

let dialogRenderCount = 0;

@Component({
  name: 'ApprovalConfirmationDialog',
  components: {
    CommentPromptForm,
  },
})
export default class ApprovalConfirmationDialog extends Vue {
  @Ref('comment')
  protected readonly commentRef!: VTextField;

  @Prop({ required: true })
  protected readonly promise!: LazyPromise<ApprovalConfirmationDialogResponse>;

  @Prop({ required: true })
  protected readonly order!: Order;

  protected dialog = true;

  protected selected: string[] = [];

  /**
   * Dialog form data
   */
  protected form = {
    comment: '',
  };

  protected items!: VAutoCompleteItem[];

  protected get comment() {
    return this.form.comment;
  }

  /**
   * Helper function for showing modal programmatically. Must be used with
   * `bind` to capture scope of caller
   */
  public static async attach(
    this: any,
    propsData: ApprovalConfirmationDialogProps,
  ): Promise<ApprovalConfirmationDialogResponse> {
    const lazyPromise = new LazyPromise();
    const { title, subtitle } = propsData;
    const instance = new ApprovalConfirmationDialog({
      parent: this,
      propsData: {
        ...propsData,
        promise: lazyPromise,
      },
      render: function(createElement: CreateElement) {
        const options: any = {
          props: {
            promise: lazyPromise,
            order: propsData.order,
          },
        };
        options.scopedSlots = {};
        if (title) {
          options.scopedSlots.title = function createTitle() {
            return createElement('span', title);
          };
        }
        if (subtitle) {
          options.scopedSlots.subtitle = function createSubtitle() {
            return createElement('span', subtitle);
          };
        }
        return createElement('approval-confirmation-dialog', options);
      },
    });

    // Prevent multiple copies of dialog from being rendered
    if (dialogRenderCount > 0) {
      lazyPromise.reject();
      return lazyPromise;
    }

    dialogRenderCount = 1;
    instance.$mount();
    return lazyPromise;
  }

  protected created() {
    this.items = this.getPermissableSections();
    this.selectAll();
  }

  /**
   * Select the first - and only the first - item from list of targets
   */
  protected selectAll() {
    this.selected = this.items.map((item) => item.value.toString());
  }

  /**
   * Remove an item from the autocomplete
   */
  protected removeItem (item: VAutoCompleteItem) {
    const index = this.selected.findIndex(o =>  o === item.value);
    if (index >= 0) this.selected.splice(index, 1);
  }

  /**
   * Confirm callback (resolve the LazyPromise)
   */
  protected confirm() {
    this.dialog = false;
    this.promise.resolve({
      sections: this.selected,
      comment: this.comment,
    });
    this.$destroy();
    dialogRenderCount = 0;
  }

  /**
   * Cancel callback (reject the LazyPromise)
   */
  protected cancel() {
    this.dialog = false;
    this.promise.reject();
    this.$destroy();
    dialogRenderCount = 0;
  }

  /**
   * Get an array of objects (containing section codes and labels) for
   * sections the current user can affect the status of
   */
  protected getPermissableSections(): VAutoCompleteItem[] {
    const items: VAutoCompleteItem[] = [];

    // Small func for fulfilling all requirements for a section's
    // approval/denial process being accessible
    const canApproveSection = (section: string) => {
      const immutableStatus = ['approved', 'denied'];
      const action = `approve_${section}` as any;
      return AuthService.getInstance().getAbility().can(action, this.order)
        && this.order.hasItemsIn(section)
        && !immutableStatus.includes(this.order.approvals[section]);
    };

    if (canApproveSection('part_a')) {
      items.push({ text: 'PPE', value: 'part_a' });
    }
    if (canApproveSection('part_b')) {
      items.push({ text: 'Swabs', value: 'part_b' });
    }
    if (canApproveSection('part_c')) {
      items.push({ text: 'N95 Masks', value: 'part_c' });
    }

    return items;
  }
}

type ApprovalConfirmationDialogProps = {
  /**
   * Title to show at top of Dialog modal
   */
  title: string;
  /**
   * Subtitle to show underneath the title at top of Dialog modal
   */
  subtitle: string;
  /**
   * Items to display in select
   */
  order: Order;
};

type ApprovalConfirmationDialogResponse = {
  /**
   * Selected form-sections to commit action on
   */
  sections: string[];
  /**
   * Comment for committed action
   */
  comment: string;
};
