var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders-item-list__wrapper"},[(!_vm.loading)?_c('div',{staticClass:"orders-item-list"},[(_vm.organizeBy === 'items')?[_c('div',{staticClass:"orders-item-list__table"},[(_vm.items.length)?_c('v-data-table',{staticClass:"orders-item-list elevation-0 outlined my-8",attrs:{"headers":_vm.headers,"items":_vm.getOrderItems(_vm.items),"custom-group":_vm.groupItems,"group-by":"product.meta.category","item-key":"product.sku","disable-pagination":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',[(isOpen)?_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('td',{attrs:{"colspan":"3"}},[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(group))])])]}},{key:"item.image",fn:function(ref){
var orderItem = ref.item;
return [_c('products-image-thumb',{staticClass:"orders-item-list__image",attrs:{"product":orderItem.getProduct(),"use-upload":false,"use-lightbox":true}})]}},{key:"item.details",fn:function(ref){
var orderItem = ref.item;
return [_c('orders-item-details',{attrs:{"item":orderItem}})]}},{key:"item.quantity",fn:function(ref){
var orderItem = ref.item;
return [_c('v-text-field',{staticClass:"mt-7",staticStyle:{"width":"8rem"},attrs:{"type":"number","outlined":"","error":orderItem.quantity < 0,"disabled":_vm.disabled},model:{value:(orderItem.quantity),callback:function ($$v) {_vm.$set(orderItem, "quantity", $$v)},expression:"orderItem.quantity"}})]}}],null,true)}):_vm._e()],1)]:_vm._l((_vm.collectionOrderItems),function(ref){
var collection = ref[0];
var items = ref[1];
return _c('div',{key:collection.id,staticClass:"orders-item-list__table"},[(items.length)?_c('v-data-table',{staticClass:"orders-item-list elevation-0 outlined my-8",attrs:{"headers":_vm.headers,"items":_vm.getOrderItems(items),"custom-group":_vm.groupItems,"group-by":"product.meta.category","disable-pagination":"","disable-filtering":"","hide-default-footer":"","item-key":"product.sku"},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var orderItem = ref.item;
return [_c('products-image-thumb',{staticClass:"orders-item-list__image",attrs:{"product":orderItem.getProduct(),"use-upload":false,"use-lightbox":true}})]}},{key:"item.details",fn:function(ref){
var orderItem = ref.item;
return [_c('orders-item-details',{attrs:{"item":orderItem}})]}},{key:"item.quantity",fn:function(ref){
var orderItem = ref.item;
return [_c('v-text-field',{staticClass:"mt-7",staticStyle:{"width":"8rem"},attrs:{"type":"number","outlined":"","error":orderItem.quantity < 0,"disabled":_vm.disabled},model:{value:(orderItem.quantity),callback:function ($$v) {_vm.$set(orderItem, "quantity", $$v)},expression:"orderItem.quantity"}})]}},{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('td',[(isOpen)?_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":toggle}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('td',{attrs:{"colspan":"3"}},[_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(group))])])]}}],null,true)},[_c('template',{slot:"top"},[_c('div',[_c('p',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.titleCase(collection.name))+": ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(collection.meta.description || '')+" ")])])])],2):_vm._e()],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }