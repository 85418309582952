





































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { Loading } from '@/lib/types';
import { User, Order, Customer } from '@/models/internal';
import { UserService, CustomerService, IAutocompleteArguments } from '@/services';
import { Logger } from '@/tools/Logger';
import prepareData from '@/models/functions/prepareData';
import { ApprovalStatus } from '../../../lib/enum';
import { debounce } from 'lodash';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'OrdersSetup',
})
export default class OrdersSetup extends Vue {
  protected siteNotFoundContactEmail = 'POCOrders@evolvewithus.ca';

  protected customerAutocompleteHideNoData = false;

  private readonly userService: UserService = UserService.getInstance();

  private readonly customerService: CustomerService = CustomerService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'OrdersSetup' });

  private readonly loading: Loading = {
    customers: false,
    users: false,
  };

  private activeUser: User = new User();

  private customersData: Customer[] = [];

  private usersData: User[] = [];

  private readonly form: any = {
    valid: false,
    email: '',
    customer: null,
    isOrderOnBehalf: false,
    orderOnBehalfUser: '',
    rules: {
      required: (value: string) => !!value || 'Required.',
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail';
      },
    },
  };

  private customerSearch = null;

  private debouncedFetchCustomers = debounce(this.fetchCustomers, 500);

  private debouncedFetchUsers = debounce(this.fetchUsers, 1000 * 10, {
    leading: true,
    maxWait: 1000 * 10,
    trailing: false,
  });

  protected get customers(): any[] {
    return this.formatCustomersData(this.customersData);
  }

  protected get users(): any[] {
    return this.usersData.map((user: any) => ({
      text: `${user.name} - ${user.email}`,
      value: user.email,
    }));
  }

  protected get selectCustomerHint(): string | undefined {
    if (this.form.customer?.id) {
      return undefined;
    }
    return 'Select the site for your order';
  }

  protected get selectUserHint(): string | undefined {
    if (this.form.orderOnBehalfUser) {
      return undefined;
    }
    return 'Select the user to order on behalf';
  }

  @Watch('customerSearch')
  protected onSearch() {
    if (this.form.customer?.name === this.customerSearch) return; // Prevent fetch on selection
    this.customersData = [];
    this.loading.customers = true;
    this.customerAutocompleteHideNoData = true;
    this.debouncedFetchCustomers();
  }

  public mounted() {
    this.init();
  }

  public async init() {
    const config = await Utility.fetchConfig();
    this.siteNotFoundContactEmail = config.portal.contact_email ? config.portal.contact_email : this.siteNotFoundContactEmail;
  }

  public created() {
    const user = this.userService.getActive();
    if (!user) throw Error('Unable to find active User');
    this.activeUser = user;
    this.form.email = user.email;
    this.fetchCustomers();
  }

  /**
   * Fetch Customer data from server and populate ORM
   */
  protected async fetchCustomers() {
    this.loading.customers = true;
    this.customerAutocompleteHideNoData = true;
    const params: IAutocompleteArguments = {
      authentication_token: this.activeUser.authentication_token,
      // approval_status: ['', ApprovalStatus.Approved, ApprovalStatus.Pending],
      name_containing: this.customerSearch,
    };

    // If logged in user is a site user, only show associated sites in autocomplete
    if (this.activeUser.roles.includes('site_user')) {
      params.show_site_requests = true;
    }

    try {
      const { customers } = await this.customerService.api.autocomplete(params);
      const preparedData = prepareData(customers, CustomerService.mapData);
      // await this.customerService.insert({ data: preparedData });
      // this.customersData = this.customerService.findIn(preparedData.map((customer: Customer) => customer.id));
      this.customersData = preparedData.map((data: any) => new Customer(data));
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.customers = false;
      this.customerAutocompleteHideNoData = false;
    }
  }

  /**
   * Fetch User data from server and populate ORM
   */
  protected async fetchUsers() {
    this.loading.users = true;
    try {
      const users = await this.userService.api.find({
        authentication_token: this.activeUser.authentication_token,
      });
      // await this.userService.insert({ data: users });
      // this.usersData = this.userService.all();
      this.usersData = users.map((user: any) => new User(user));
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading.users = false;
    }
  }

  /**
   * User can place an Order for a Customer and/or another User
   */
  protected canOrderFor() {
    return this.$ability.can('order_for', User);
  }

  protected onOrderOnBehalfChange(on: boolean) {
    if (on) {
      this.debouncedFetchUsers();
    }
  }

  /**
   * Create Order model data and navigate to orders-create
   */
  protected async onContinue() {
    if (!this.form.customer) {
      throw Error('Must select a customer');
    }

    const order = new Order();

    order.customer = this.form.customer;
    order.submitters_email = this.form.email;
    order.ship_address = {
      facility_name: this.form.customer.name,
      city: this.form.customer.city,
      delivery_address: this.form.customer.address,
      postal: this.form.customer.postal_zip,
      country: this.form.customer.country,
      province: this.form.customer.prov_state,
      contact_name: this.form.customer.contact,
      contact_phone: this.form.customer.contact_phone,
      contact_email: this.form.customer.contact_email,
      cost_center: this.form.customer.cost_center,
    };
    order.bill_address = {
      facility_name: this.form.customer.bt_name,
      city: this.form.customer.bt_city,
      delivery_address: this.form.customer.bt_address,
      postal: this.form.customer.bt_postal_zip,
      country: this.form.customer.bt_country,
      province: this.form.customer.bt_prov_state,
      contact_name: this.form.customer.bt_contact,
      contact_phone: this.form.customer.bt_phone,
      contact_email: this.form.customer.bt_email,
    };
    if (this.form.customer.general_notes !== 'null') {
      order.order_detail.delivery_instructions = this.form.customer.general_notes;
    }
    if (this.form.isOrderOnBehalf) {
      order.submitters_email = this.form.orderOnBehalfUser;
    }

    this.$router.push({
      name: 'orders-create',
      query: {
        customerId: String(this.form.customer.id),
        order: JSON.stringify(order),
      },
    });
  }

  /**
   * Sort and format Customer data for display in v-data-table component
   */
  protected formatCustomersData(customers: Customer[]) {
    return customers
      .sort((a, b) => ((a.name < b.name) ? -1 : 1))
      .map((customer) => ({
        text: (customer.approval_status === ApprovalStatus.Pending) ?
          `${customer.name} - (Pending Approval)` :
          customer.name,
        value: customer,
        disabled: customer.approval_status === ApprovalStatus.Pending,
      }));
  }
}
