





















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { OrderItem, Product } from '@/models/internal';
import { ProductService, UserService } from '@/services';

@Component({
  name: 'OrdersItemDetails',
})
export default class OrdersItemDetails extends Vue {
  @Prop({ required: true, default: null })
  public readonly item!: OrderItem;

  private readonly productService = ProductService.getInstance();

  private readonly userService = UserService.getInstance();

  private product!: Product;

  protected get title() {
    return this.product.meta?.title || this.product.title;
  }

  protected get min() {
    return 0;
  }

  protected get max() {
    const { product } = this;

    if (!product) return Infinity;

    return typeof this.product?.meta.max_quantity === 'string'
      ? this.product?.meta.max_quantity
      : `${this.product?.meta.max_quantity} units`;
  }

  protected get available(): boolean {
    if (!this.product.inventory.quantity) return false;

    return this.product.inventory.quantity > 0;
  }

  protected get unit() {
    const unitOfMeasureSize = this.product?.meta.unit_of_measure_size?.split('=') ?? [];
    let size = unitOfMeasureSize[1];
    if (!size) size = '1';
    const unitType = this.product?.meta.unit_of_measure_type?.toLocaleLowerCase();
    switch (unitType) {
      case 'canister':
        return 'Canisters';
      case 'box':
        return `Boxes of ${size}`;
      case 'case':
        return `Cases of ${size}`;
      case 'pack':
        return `Packs of ${size}`;
      case 'each':
      default:
        return 'Singles';
    }
  }

  protected get details() {
    const meditech = (this.product?.meta?.meditech)
      ? `Meditech: ${this.product.meta.meditech}`
      : null;

    const peoplesoft = (this.product?.meta?.peoplesoft)
      ? `PeopleSoft: ${this.product.meta.peoplesoft}`
      : null;

    let description = this.product?.meta?.description || this.product.description;

    description = (Array.isArray(description))
      ? description.join(', ')
      : description;

    return ([
      meditech,
      peoplesoft,
      description,
    ]).filter(Boolean);
  }

  public created() {
    const product = this.item.getProduct();

    if (!product) throw Error('Cannot find Product record associated with OrderItem');

    this.product = product;
  }
}
