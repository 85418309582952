

































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { OrderStatus } from '@/lib/enum/Status.enum';
import { IModelView } from '@/lib/interfaces/ModelView.interface';
import { InfoListSection as InfoListSectionType, InfoList as InfoListType } from '@/lib/types';
import { OrderService, UserService } from '@/services';
import prepareData from '@/models/functions/prepareData';
import CommentsView, { showComments } from '@/components/Comments/CommentsView.vue';
import InfoListSection from '@/components/InfoList/InfoListSection.vue';
import { Logger } from '@/tools/Logger';
import { Order, OrderItem } from '@/models/internal';
import { FulfillmentCollectionView } from '@/components/Fulfillment/FulfillmentCollectionView.vue';
import OrdersItemList from './components/OrdersItemList.vue';
import OrderStatusIndicator from '@/components/StatusIndicator/OrderStatusIndicator.vue';
import OrdersTimeline from './components/OrdersTimeline/OrdersTimeline.vue';
import OrdersTimelineButton from './components/OrdersTimeline/OrdersTimelineButton.vue';

import { Utility } from '@/tools/Utility';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';
import { ContextBarAction } from '@/components/ContextBar/types/ContextBarAction.type';
import { RawLocation } from 'vue-router';

@Component({
  name: 'OrdersView',
  components: {
    CommentsView,
    InfoListSection,
    OrdersItemList,
    OrdersTimeline,
    OrdersTimelineButton,
    OrderStatusIndicator,
    FulfillmentCollectionView,
  },
})
export default class OrdersView extends Vue implements IModelView<Order>, IUpdateContextBar {
  @Ref('root')
  protected readonly rootRef!: Element;

  @Ref('comment-view')
  protected readonly commentViewRef!: HTMLElement;

  @Prop({ required: true })
  protected readonly id!: number | string;

  @Prop({ required: true })
  protected readonly canUpdate!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly canUpdateStatus!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly edit!: (order: Order) => void;

  @Prop({ required: true })
  protected readonly approve!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly deny!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly afterSave!: (result: any, order: Order, location?: RawLocation) => void;

  @Prop({ required: true })
  protected readonly getHiddenSections!: () => string[];

  @Prop({ required: true })
  protected readonly downloadPdf!: (id: number) => Promise<void>;

  @Sync('context/overlay@visible')
  protected loading!: boolean;

  public data = new Order();

  /**
   * Customer Service
   */
  protected readonly orderService: OrderService = OrderService.getInstance();

  /**
   * User Service
   */
  protected readonly userService: UserService = UserService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'OrdersView' });

  protected readonly title = 'View Order';

  protected readonly subtitle = `
    Review an Order's details that were used during the initial placement of
    the Order.
  `;

  /**
   * Is the timeline drawer open
   */
  protected isTimelineOpen = false;

  protected showCommentsFab = false;

  /**
   * Order's shipping address
   */
  protected get shipping(): InfoListType {
    return [
      {
        label: 'Contact Name',
        value: this.data.ship_address.contact_name,
        icon: 'mdi-account',
        divider: true,
      }, {
        label: 'Contact Phone',
        value: Utility.formatPhone(this.data.ship_address.contact_phone),
        icon: 'mdi-phone',
        divider: true,
      }, {
        label: 'Contact Email',
        value: this.data.ship_address.contact_email,
        icon: 'mdi-email',
        divider: true,
      }, {
        label: 'Company Name',
        value: this.data.ship_address.facility_name,
        icon: 'mdi-domain',
        divider: true,
      }, {
        label: 'Address',
        value: this.data.ship_address.delivery_address,
        icon: 'mdi-map-marker',
        divider: false,
        siblings: [
          {
            label: 'City',
            value: this.data.ship_address.city,
          }, {
            label: 'Postal Code / Zip',
            value: this.data.ship_address.postal,
          }, {
            label: 'Province',
            value: this.data.ship_address.province,
          }, {
            label: 'Country',
            value: this.data.ship_address.country,
          },
        ],
      }, {
        label: 'Cost Center #',
        value: this.data.ship_address.cost_center,
        icon: 'mdi-domain',
        divider: true,
      },
    ];
  }

  /**
   * Order's billing address
   */
  protected get billing(): InfoListType {
    return [
      {
        label: 'Contact Name',
        value: this.data.bill_address.contact_name,
        icon: 'mdi-account',
        divider: true,
      }, {
        label: 'Contact Phone',
        value: Utility.formatPhone(this.data.bill_address.contact_phone),
        icon: 'mdi-phone',
        divider: true,
      }, {
        label: 'Contact Email',
        value: this.data.bill_address.contact_email,
        icon: 'mdi-email',
        divider: true,
      }, {
        label: 'Company Name',
        value: this.data.bill_address.facility_name,
        icon: 'mdi-domain',
        divider: true,
      }, {
        label: 'Address',
        value: this.data.bill_address.delivery_address,
        icon: 'mdi-map-marker',
        divider: false,
        siblings: [
          {
            label: 'City',
            value: this.data.bill_address.city,
          }, {
            label: 'Postal Code / Zip',
            value: this.data.bill_address.postal,
          }, {
            label: 'Province',
            value: this.data.bill_address.province,
          }, {
            label: 'Country',
            value: this.data.bill_address.country,
          },
        ],
      },
    ];
  }

  /**
   * Order's details: date, priority, etc.
   */
  protected get details(): InfoListType {
    return [
      {
        label: 'ID',
        value: this.data.id,
        icon: 'mdi-identifier',
        divider: true,
      },
      {
        label: 'Date',
        value: Utility.formatDate(this.data.order_detail.date, true),
        icon: 'mdi-calendar',
        divider: true,
      }, {
        label: 'Priority',
        value: Utility.titleCase(this.data.order_detail.priority),
        icon: 'mdi-truck-fast-outline',
        divider: true,
      }, {
        label: 'Delivery Instructions',
        value: this.data.order_detail.delivery_instructions,
        icon: 'mdi-playlist-edit',
        divider: true,
      },
    ];
  }

  /**
   * All of the items on an Order
   */
  protected get items(): OrderItem[] {
    return this.data.order_items.filter((sku) => sku.quantity > 0);
  }

  /**
   * All the columns in the Customer details card to be iterated
   * on by section
   */
  protected get sections(): InfoListSectionType[] {
    return [
      {
        label: 'Shipping Address',
        items: this.shipping,
      }, {
        label: 'Billing Address',
        items: this.billing,
      }, {
        label: 'Order Details',
        items: this.details,
      },
    ];
  }

  protected get status() {
    if (this.data.status === OrderStatus.Reviewed) {
      return Utility.titleCase('reviewed');
    }
    return Utility.titleCase(this.data.status);
  }

  @Watch('loading', { immediate: false })
  protected watchLoading(loading: boolean) {
    if (!loading) this.afterLoading();
  }

  public created() {
    this.init();
  }

  public updateContextBar() {
    const actions: ContextBarAction[] = [
      {
        icon: 'mdi-comment-quote-outline',
        color: 'primary',
        label: 'View Comments',
        callback: () => this.$vuetify.goTo(this.commentViewRef),
      },
    ];

    if (this.canUpdate(this.data)) {
      actions.unshift({
        icon: 'mdi-pencil-outline',
        color: 'primary',
        label: 'Edit',
        to: { name: 'orders-edit', params: { id: String(this.id) } },
      });
    }

    if (this.canUpdateStatus(this.data)) {
      actions.unshift(
        {
          icon: 'mdi-clipboard-check-outline',
          color: 'success',
          label: 'Approve',
          callback: () => this.onClickApprove(this.data),
        },
        {
          icon: 'mdi-clipboard-remove-outline',
          color: 'error',
          label: 'Deny',
          callback: () => this.onClickDeny(this.data),
        },
      );
    }

    ContextBarManager.setActions(...actions);
  }

  public async fetchData(): Promise<Order> {
    const order = await this.orderService.api.findOne({
      authentication_token: this.userService.getActiveToken(),
      id: this.id,
    });
    if (!order) throw Error('Unable to fetch existing order');
    return order;
  }

  public async init(): Promise<void> {
    this.loading = true;
    this.isTimelineOpen = false;

    try {
      const data = await this.fetchData();
      const preparedData = prepareData(data, OrderService.mapData);

      this.data = new Order(preparedData);

      // Render out the comments
      const boundShowComments = showComments.bind(this);
      boundShowComments(this.commentViewRef, {
        parentId: parseInt(this.id as string, 10),
        parentEntity: 'draft_orders',
      });
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }

    if (this.$route.query.scroll === 'comments') {
      this.$vuetify.goTo(this.commentViewRef);
    }
  }

  /**
   * Click handler for TableRowActions approve button
   */
  protected async onClickApprove(order: Order) {
    const success = await this.approve(order);
    this.afterSave(success, order, { name: 'orders-list' });
  }

  /**
   * Click handler for TableRowActions deny button
   */
  protected async onClickDeny(order: Order) {
    const success = await this.deny(order);
    this.afterSave(success, order, { name: 'orders-list' });
  }

  protected onIntersect(entry: IntersectionObserverEntry[]) {
    this.showCommentsFab = !entry[0].isIntersecting;
  }

  /**
   * Callback for when the view has loaded it's data and is now in a
   * ready-state for interacting with child elements and refs.
   */
  private afterLoading() {
    this.updateContextBar();

    // Check if the comments are rendered, and if the route has specified a 'goto'
    if (this.$route.query.goto === 'comments') this.$nextTick(this.scrollToComments);
  }

  private scrollToComments() {
    return this.$vuetify.goTo(this.commentViewRef);
  }
}
