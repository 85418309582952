import { Config } from './types/Config.type';
import moment, { Moment, DurationInputArg1, DurationInputArg2 } from 'moment';

/** Weekdays */
const WEEKDAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

/**
 * ServiceLevelAgreement
 *
 * Functions for calculating estimated delivery times and other
 * expectations derived from a service-level agreement (SLA).
 */
export class ServiceLevelAgreement {
  /** List of defined priorities. */
  private readonly priorities!: ServiceLevelAgreementPriority[];

  /** Map of priority data, keyed by elements within the `priorities` array. */
  private readonly priorityMap!: ServiceLevelAgreementPriorityMap;

  /**
   * Constructor.
   * @param {ServiceLevelAgreementParameters} parameters Parameters
   * @param {ServiceLevelAgreementPriorities} parameters.priorities Priorities object
   */
  public constructor(parameters: ServiceLevelAgreementParameters) {
    this.priorities = parameters.priorities;
    this.priorityMap = parameters.priority;
  }

  /**
   * Estimate the soonest delivery date.
   * @param {string|Moment} date
   * @param {string} priority
   * @param {boolean} stringify
   */
  public estimateDelivery(date: string | Moment, priority: ServiceLevelAgreementPriority, stringify?: boolean): string | Moment {
    const [value, unit] = this.getPriorityDetails(priority).delivery_time as [DurationInputArg1, DurationInputArg2];
    const fromDate = this.getDateObject(date);
    const toDate = this.getDateObject(fromDate).add(value, unit);
    const estimatedDate = this.nextDate(toDate, priority);
    return (stringify) ? estimatedDate.format() : estimatedDate;
  }

  /**
   * Get priority data based on the client's available service-level agreements.
   * If nothing is found, or a priority is unsupported, throw an error.
   * @param priority Priority name
   * @throws {Error} Invalid priority provided - unsupported
   * @returns Priority data found in map
   */
  private getPriorityDetails(priority: ServiceLevelAgreementPriority): ServiceLevelAgreementPriorityMap['priority'] {
    if (this.priorities.includes(priority) && this.priorityMap[priority]) {
      return this.priorityMap[priority];
    }
    throw Error(`Cannot find service-level agreement details for priority "${priority}"`);
  }

  /**
   * Get the next viable delivery date (soonest), or return the provided date if it
   * is acceptable
   * @param date
   * @param priority
   */
  private nextDate(date: string | Moment, priority: ServiceLevelAgreementPriority): Moment {
    const dateObject = this.getDateObject(date);
    const availabilities = this.getAvailability(priority);
    const nextDateObject = dateObject.clone();

    let weekday = WEEKDAYS[dateObject.day()];
    let availability;
    let start;
    let end;
    let startDateObject;
    let endDateObject;

    // If the current day is not available, forward weekday to next available
    if (!availabilities[weekday]) {
      weekday = this.nextAvailableWeekday(weekday, priority);
      availability = availabilities[weekday];
      start = availability.start;
      end = availability.end;
      startDateObject = this.getDateObject(start, 'h:mma');
      endDateObject = this.getDateObject(end, 'h:mma');

      nextDateObject.day(weekday);
      nextDateObject.hour(startDateObject.hour());
      nextDateObject.minute(startDateObject.minute());
    } else {
      availability = availabilities[weekday];
      start = availability.start;
      end = availability.end;
      startDateObject = this.getDateObject(start, 'h:mma');
      endDateObject = this.getDateObject(end, 'h:mma');
    }

    // If the time provided is BEFORE BUSINESS hours, assign the time that
    // it opens
    if (nextDateObject.hour() < startDateObject.hour()
      || (nextDateObject.hour() === startDateObject.hour()
      && nextDateObject.minute() < startDateObject.minute())
    ) {
      // Same day but before it opens
      nextDateObject.hour(startDateObject.hour());
      nextDateObject.minute(startDateObject.minute());

    // If AFTER HOURS, than find the next available day, and set the time
    // to opening hours of that day
    } else if (nextDateObject.hour() > endDateObject.hour()
      || (nextDateObject.hour() === endDateObject.hour()
      && nextDateObject.minute() > endDateObject.minute())
    ) {
      const nextWeekday = this.nextAvailableWeekday(weekday, priority);
      availability = availabilities[nextWeekday];
      start = availabilities[nextWeekday].start;
      end = availabilities[nextWeekday].end;
      startDateObject = this.getDateObject(start, 'h:mma');
      endDateObject = this.getDateObject(end, 'h:mma');
      nextDateObject.day(nextWeekday);
      nextDateObject.hour(startDateObject.hour());
      nextDateObject.minute(startDateObject.minute());
    }

    if (dateObject.isAfter(nextDateObject)) {
      nextDateObject.add(7, 'days');
    }

    return nextDateObject;
  }

  /**
   * Return the next day of the week that the SLA has availabilities for (monday -> sunday)
   * @param weekday
   * @param priority
   */
  private nextAvailableWeekday(weekday: string, priority: ServiceLevelAgreementPriority) {
    const availability = this.getAvailability(priority);

    let idx = (WEEKDAYS.indexOf(weekday) !== 6) ? WEEKDAYS.indexOf(weekday) + 1 : 0;
    let nextDay = WEEKDAYS[idx];
    while (nextDay !== weekday) {
      nextDay = WEEKDAYS[idx];
      if (availability[nextDay]) {
        return nextDay;
      }
      idx = (idx !== 6) ? idx + 1 : 0;
    }

    if (availability[weekday]) {
      return weekday;
    }

    throw Error('Cannot find the next available weekday');
  }

  /**
   * Get an availability object for a given priority.
   * @param priority
   */
  private getAvailability(priority: ServiceLevelAgreementPriority) {
    const { processing_availability } = this.getPriorityDetails(priority);
    return processing_availability;
  }

  /**
   * Get a date object from string or object
   * @param date datestring or object
   */
  private getDateObject(date: string | Moment, format?: string): Moment {
    return (format) ? moment(date, format) : moment(date);
  }
}

export default ServiceLevelAgreement;

/**
 * ServiceLevelAgreement types from app Config contracts
 */
type ServiceLevelAgreementParameters = Config['defaults']['service_level_agreements'];
type ServiceLevelAgreementPriority = Config['defaults']['service_level_agreements']['priorities'][0];
type ServiceLevelAgreementPriorityMap = Config['defaults']['service_level_agreements']['priority'];
