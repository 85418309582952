








































import { Fulfillment, Order, prepareData } from '@/models/internal';
import Logger from '@/tools/Logger';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { FulfillmentView } from './FulfillmentView.vue';
import { FulfillmentService, UserService } from '@/services';
import { ICollectionView } from '@/lib/interfaces';
import { LoadingIndicator } from '@/components/LoadingIndicator.vue';

@Component({
  name: 'FulfillmentCollectionView',
  components: {
    FulfillmentView,
    LoadingIndicator,
  },
})
export class FulfillmentCollectionView extends Vue implements ICollectionView<Fulfillment> {
  @Prop({ required: true })
  protected readonly order!: Order;

  public data: Fulfillment[] = [];

  protected readonly logger = new Logger({ context: 'FulfillmentCollectionView' });

  protected readonly fulfillmentService = FulfillmentService.getInstance();

  protected readonly userService = UserService.getInstance();

  protected loading = false;

  public created() {
    this.init();
  }

  public async init() {
    this.loading = true;
    try {
      this.data = await this.fetchData();
    } catch (error) {
      this.logger.error(error);
    }
    this.loading = false;
  }

  public async fetchData(): Promise<Fulfillment[]> {
    const data = await this.fulfillmentService.api.find({
      order_id: this.order.id,
      authentication_token: UserService.getInstance().getActiveToken(),
    });
    const preparedData = prepareData(data, FulfillmentService.mapData);
    const fulfillments = preparedData.map((fulfillment: any) => new Fulfillment(fulfillment));
    if (!fulfillments) {
      throw Error('Unable to fetch data');
    }
    return fulfillments;
  }
}

export default FulfillmentCollectionView;
