




















































import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import { ICreateFormView } from '@/lib/interfaces';
import { Order, OrderItem, Collection } from '@/models/internal';
import { CollectionService, CustomerService, OrderService, ProductService, UserService } from '@/services';
import OrdersForm from './components/OrdersForm.vue';
import OrdersItemList from './components/OrdersItemList.vue';
import { Logger } from '@/tools/Logger';
import { OrderForm } from '@/models/forms/OrderForm';
import { Sync } from 'vuex-pathify';
import { CommentPromptDialog } from '@/components/Comments/components/CommentPromptDialog.vue';
import { Utility } from '@/tools/Utility';

// import '@/lib/Container';

@Component({
  name: 'OrdersCreate',
  components: {
    OrdersForm,
    OrdersItemList,
  },
})
export default class OrdersCreate extends Vue implements ICreateFormView<Order> {
  @Ref('orders-form')
  public readonly formRef!: any;

  @Sync('context/overlay@visible')
  public isOverlayVisible!: boolean;

  /**
   * A Order than has been previous created and hydrated,
   * or a brand new Order object
   */
  @Prop({ required: true })
  protected readonly order!: Order;

  @Prop({ required: true })
  protected readonly customerId!: string | number;

  @Prop({ required: true })
  protected readonly canUpdate!: () => boolean;

  @Prop({ required: true })
  protected readonly canUpdateStatus!: () => boolean;

  @Prop({ required: true })
  protected readonly getHiddenSections!: (order: Order) => string[];

  @Prop({ required: true })
  protected readonly validateItems!: (items: OrderItem[]) => boolean;

  public form!: OrderForm;

  protected readonly orderService: OrderService = OrderService.getInstance();

  protected readonly collectionService: CollectionService = CollectionService.getInstance();

  protected readonly customerService: CustomerService = CustomerService.getInstance();

  protected readonly productService: ProductService = ProductService.getInstance();

  protected readonly userService: UserService = UserService.getInstance();

  protected readonly title = 'Create Order';

  protected readonly subtitle = 'Create a new Order request and save it to be reviewed.';

  protected contactEmail = 'POCOrders@evolvewithus.ca';

  protected readonly logger: Logger = new Logger({ context: 'OrdersCreate' });

  protected loading = false;

  protected sections: Map<Collection, OrderItem[]> = new Map<Collection, OrderItem[]>();

  protected get schema() {
    return this.form.getSchema();
  }

  protected get model() {
    return this.form.getModel();
  }

  protected set model(model) {
    this.form.setModel(model);
  }

  public created() {
    this.init();
  }

  public async init() {
    const config = await Utility.fetchConfig();
    this.contactEmail = config.portal.contact_email ? config.portal.contact_email : this.contactEmail;

    this.loading = true;

    try {
      this.form = new OrderForm(this.order);

      const collections = await this.fetchCollections();

      collections.forEach(collection => {
        const orderItems = collection
          .products
          .filter((product) => product.meta.valid && !product.inventory.stop_selling)
          .map(
            (product: any) => new OrderItem({
              order_id: 0,
              product_id: product.id,
              quantity: 0,
              form_section: collection.code,
            }),
          );

        this.sections.set(collection, orderItems);
        this.model.order_items = this.model.order_items.concat(orderItems);
      });
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  public async save(): Promise<any> {
    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User');

    // Remap the OrderItems because server is expecting meta as primary
    // structure
    const orderItems = this.model.order_items.map((item) => {
      if (!item.product) item.getProduct();
      return {
        ...item.product.meta,
        form_section: item.form_section,
        sku: item.product.sku,
        quantity: item.quantity,
      };
    });

    try {
      this.isOverlayVisible = true;

      const draftOrder = { ...this.model, order_items: orderItems };

      const response = await this.orderService.api.create({
        authentication_token: token,
        draft_order: draftOrder,
        submitters_email: this.model.submitters_email,
      });
      await this.orderService.insert({
        data: this.model,
        insertOrUpdate: ['users'],
      });
      this.$router.push({
        name: 'orders-list',
      });
      return response;
    } catch (error) {
      this.$genify.alert('Sorry, there was an error while submitting this Order.', 'error', {
        prominent: true,
        transition: 'scale-transition',
      });
      this.logger.error(error);
    } finally {
      this.isOverlayVisible = false;
    }
  }

  protected async fetchCollections(): Promise<Collection[]> {
    const { collections } = await this.customerService.api.findCollectionsProducts({
      id: this.customerId,
      authentication_token: this.userService.getActiveToken(),
    });

    await this.collectionService.insert({ data: collections });

    return this.collectionService
      .query()
      .with('products')
      .whereIdIn(collections.map((c: any) => c.id))
      .get();
  }

  /**
   * Listener for Save button click
   */
  protected onSaveForm() {
    this.formRef.save();
  }

  /**
   * Listener for Reset button click
   */
  protected onResetForm() {
    this.formRef.reset();
    this.model.order_items.forEach((item) => {
      item.quantity = 0;
    });
  }

  /**
   * Callback for FormBase fields validation errors on save
   */
  protected onError() {
    this.$genify.alert(
      [
        'Uh-Oh! Unable to submit this Order due to invalid or missing Site data during setup.',
        'Please contact an administrator at ' + this.contactEmail + ' to resolve this issue.',
      ],
      'error',
      {
        prominent: true,
        transition: 'scale-transition',
      },
    );
  }

  protected async onClickSave(): Promise<void> {
    try {
      const isValid = await this.validateItems(this.model.order_items);
      if (!isValid) return;

      // Prompt for site user comment (optional)
      const { comment } = await CommentPromptDialog.attach({
        required: false,
        title: 'Submitting Order',
        subtitle: 'Feel free to add any additional details below before finalizing your new order!',
        cancelText: 'Cancel',
        confirmText: 'Continue',
      }).apply(this);
      this.model.notes = comment;
      await this.save();
    } catch (error) {
      if (error) this.logger.error(error);
    }
  }
}
