















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'OrdersTimelineButton',
})
export default class OrdersTimelineButton extends Vue {
  @Prop({ required: true })
  private readonly value!: boolean;

  private onClick() {
    this.$emit('input', !this.value);
  }
}
