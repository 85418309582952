










































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import OrdersItemDetails from './OrdersItemDetails.vue';
import { Asset, Collection, OrderItem } from '@/models/internal';
import { DataTableHeader } from 'vuetify';
import { Utility } from '@/tools/Utility';
import { ProductService } from '@/services';
import { Logger } from '@/tools/Logger';
import { ProductsImageThumb } from '@/components/Products/ProductsImageThumb.vue';

@Component({
  name: 'OrdersItemList',
  components: {
    OrdersItemDetails,
    ProductsImageThumb,
  },
})
export default class OrdersItemList extends Vue {
  /**
   * Sections of Order Items organized by Collections
   */
  @Prop({ required: false, default: () => new Map() })
  protected sections!: Map<Collection, OrderItem[]>;

  /**
   * Flat list of OrderItems
   */
  @Prop({ required: false, default: () => [] })
  protected items!: OrderItem[];

  /**
   * How to organize the list of OrderItems - group by collection or
   * a flat list of items.
   */
  @Prop({ required: false, default: 'sections', validator: (v) => ['sections', 'items'].includes(v) })
  protected organizeBy!: 'sections'|'items';

  /**
   * Disables all the input fields in the list
   */
  @Prop({ required: false, default: () => [] })
  protected hideSections!: string[];

  /**
   * Disables all the input fields in the list
   */
  @Prop({ required: false, default: false })
  protected disabled!: boolean;

  protected readonly productService = ProductService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'OrdersItemList' });

  /**
   * Headers for each table group
   */
  protected readonly headers: DataTableHeader[] = [
    { text: 'Image', value: 'image', width: 200, sortable: false },
    { text: 'Category', value: 'category', width: 100, sortable: false },
    { text: 'Details', value: 'details', sortable: false },
    { text: 'Quantity', value: 'quantity', width: 100, sortable: false },
  ];

  protected loading = false;

  /**
   * Map of Collection to OrderItems turned Array for iterating
   */
  protected get collectionOrderItems() {
    return Array.from(this.sections.entries());
  }

  public created() {
    if (this.organizeBy === 'items' && !this.items) {
      throw Error('Unable to organize by items if `items` prop not provided');
    }
  }

  /**
   * Custom sort function for displaying Orderitems per their Product's
   * sort weight
   */
  protected sortItems(items: OrderItem[]): OrderItem[] {
    return items.sort((a, b) => {
      const aWeight = parseInt(a.product.meta.sort_weight) || Infinity;
      const bWeight = parseInt(b.product.meta.sort_weight) || Infinity;
      if (aWeight > bWeight) return 1;
      if (aWeight < bWeight) return -1;
      return 0;
    });
  }

  /**
   * Group OrderItems by their Product's category
   */
  protected groupItems(items: OrderItem[]): Array<{ name: string; items: OrderItem[] }> {
    const groupsMap = this.sortItems(items).reduce((acc, item) => {
      const category = item.product.meta.category || 'Misc';
      if (!acc[category]) {
        acc[category] = [item];
      } else {
        acc[category].push(item);
      }
      return acc;
    }, {} as Record<string, OrderItem[]>);

    const results = Object.keys(groupsMap).map(key => ({
      name: key,
      items: groupsMap[key],
    }));

    return results;
  }

  /**
   * Hydrate OrderItem with Product data if it isn't there already.
   *
   * TODO: Find out why this is null
   */
  protected getOrderItems(items: OrderItem[]) {
    items.forEach((orderItem) => {
      if (orderItem.product) return;

      const product = orderItem.getProduct();
      if (product) {
        orderItem.product = product;
        orderItem.product.$update({ data: {
          meta: {
            ...orderItem.product.meta,
            sort_weight: parseInt(orderItem.product.meta.sort_weight) || Infinity,
          },
        } });
      } else {
        const error = new Error(`Product data missing from OrderItem. Product ID is ${orderItem.product_id}`);
        this.logger.error(error);
      }
    });

    return items;
  }

  protected getProductImage(orderItem: OrderItem): Asset | null {
    const product = orderItem.getProduct();
    if (!product) throw Error('Unable to get Product record from OrderItem');
    return product.latestImageAsset();
  }

  /**
   * TitleCase string mutator
   */
  protected titleCase(input: string): string {
    return Utility.titleCase(input);
  }
}
