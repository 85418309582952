












import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';

@Component({
  name: 'CommentPromptForm',
})
export class CommentPromptForm extends Vue {
  @PropSync('form', { required: true })
  protected readonly syncedForm!: Record<string, any>;

  @Prop({ required: false, default: 'comment-prompt-form-comment' })
  protected readonly name!: string;

  @Prop({ required: false, default: 'Comment' })
  protected readonly label!: string;

  @Prop({ required: false, default: 'Write something here ...' })
  protected readonly placeholder!: string;
}

export default CommentPromptForm;
