





















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { UserService } from '@/services/UserService';
import { User } from '@/models/internal';
import { InputValidationRule } from 'vuetify';

@Component({
  name: 'CommentCreateField',
})
export default class CommentCreateField extends Vue {
  @Prop({ required: false, default: false })
  private readonly loading!: boolean;

  @Ref('input')
  private readonly inputRef!: any;

  protected readonly userService: UserService = UserService.getInstance();

  protected readonly user: User = this.userService.getActive() as User;

  /**
   * Max character count for the input field
   */
  protected counter = 500;

  /**
   * Validation state for input field
   */
  protected invalid = true;

  /**
   * Starting value of input field
   */
  private value = '';

  /**
   * Rules for validating the input field's text
   */
  private rules: InputValidationRule[] = [
    (value) => !!value || 'Comment must be provided before submitting',
    (value) => value.length > 0 || 'Comment must contain at least one character',
    (value) => value.length <= 500 || 'Comment cannot be longer than 500 characters',
  ];

  /**
   * Input event (any change to value) callback for input field
   */
  protected input(): void {
    this.invalid = !this.rules.every((cb) => cb(this.value) === true);
  }

  /**
   * Keyup event callback for input field
   */
  protected keyup(event: KeyboardEvent): void {
    if (event.key !== 'Enter') {
      return;
    }
    this.submit();
  }

  /**
   * Submit event callback, emits an event of `submit`
   */
  private submit(): void {
    this.$emit('submit', this.value);
    this.value = '';
    this.$nextTick(() => {
      this.inputRef.resetValidation();
      this.invalid = true;
    });
  }
}
