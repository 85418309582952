














































import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import { Collection, Order, OrderItem } from '@/models/internal';
import { OrderForm } from '@/models/forms/OrderForm';
import { CollectionService, OrderService, UserService } from '@/services';
import { IEditFormView } from '@/lib/interfaces';
import prepareData from '@/models/functions/prepareData';
import { Logger } from '@/tools/Logger';
import OrdersForm from './components/OrdersForm.vue';
import OrdersFormControls from './components/OrdersFormControls.vue';
import OrdersItemList from './components/OrdersItemList.vue';
import OrdersTimeline from './components/OrdersTimeline/OrdersTimeline.vue';
import OrdersTimelineButton from './components/OrdersTimeline/OrdersTimelineButton.vue';
import { Sync } from 'vuex-pathify';
import CommentPromptDialog from '@/components/Comments/components/CommentPromptDialog.vue';
import { showComments } from '@/components/Comments/CommentsView.vue';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';
import { ContextBarAction } from '@/components/ContextBar/types/ContextBarAction.type';
import { RawLocation } from 'vue-router';

@Component({
  name: 'OrdersEdit',
  components: {
    OrdersForm,
    OrdersItemList,
    OrdersFormControls,
    OrdersTimeline,
    OrdersTimelineButton,
  },
})
export default class OrdersEdit extends Vue implements IEditFormView<Order>, IUpdateContextBar {
  @Ref('orders-form')
  public readonly formRef!: any;

  @Prop({ required: true })
  public readonly save!: (order: Order) => Promise<any>;

  @Sync('context/overlay@visible')
  public loading!: boolean;

  @Prop({ required: true })
  public readonly id!: string | number;

  @Ref('comment-view')
  protected readonly commentViewRef!: HTMLElement;

  @Prop({ required: true })
  protected readonly canUpdate!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly canUpdateStatus!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly edit!: (order: Order) => void;

  @Prop({ required: true })
  protected readonly approve!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly deny!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly afterSave!: (result: any, order: Order, location?: RawLocation) => void;

  @Prop({ required: true })
  protected readonly getHiddenSections!: () => string[];

  public readonly title = 'Edit Order';

  public readonly subtitle = 'Make changes to an existing Order and save. All fields are required.';

  public isTimelineOpen = false;

  public form!: OrderForm;

  public selectedItem = null;

  protected readonly orderService: OrderService = OrderService.getInstance();

  protected readonly userService: UserService = UserService.getInstance();

  protected readonly collectionService: CollectionService = CollectionService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'OrdersEdit' });

  protected sections: Map<Collection, OrderItem[]> = new Map();

  protected showCommentsFab = false;

  protected get schema() {
    return this.form.getSchema();
  }

  protected get model() {
    return this.form.getModel();
  }

  protected set model(model) {
    this.form.setModel(model);
  }

  @Watch('loading', { immediate: false })
  protected watchLoading(loading: boolean) {
    if (!loading) this.afterLoading();
  }

  public created() {
    this.init();
  }

  public afterLoading() {
    this.updateContextBar();
  }

  public updateContextBar() {
    const actions: ContextBarAction[] = [
      {
        icon: 'mdi-content-save-outline',
        color: 'primary',
        label: 'Save',
        callback: () => this.onClickSave(this.model),
      },
      {
        icon: 'mdi-comment-quote-outline',
        color: 'primary',
        label: 'View Comments',
        callback: () => this.$vuetify.goTo(this.commentViewRef),
      },
    ];

    if (this.canUpdateStatus(this.model)) {
      actions.unshift(
        {
          icon: 'mdi-clipboard-check-outline',
          color: 'success',
          label: 'Approve',
          callback: () => this.onClickApprove(),
        },
        {
          icon: 'mdi-clipboard-remove-outline',
          color: 'error',
          label: 'Deny',
          callback: () => this.onClickDeny(),
        },
      );
    }

    ContextBarManager.setActions(...actions);
  }

  public async init(): Promise<void> {
    this.loading = true;
    this.isTimelineOpen = false;

    try {
      const data = await this.fetchData();
      const preparedData = prepareData(data, OrderService.mapData);

      this.form = new OrderForm(new Order(preparedData));

      // Render out the comments
      const boundShowComments = showComments.bind(this);
      boundShowComments(this.commentViewRef, {
        parentId: parseInt(this.id as string, 10),
        parentEntity: 'draft_orders',
      });
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(
        [
          'Sorry, there was an error loading this Order.',
          'Please try again later.',
        ],
        'error',
        {
          prominent: true,
          transition: 'scale-transition',
        },
      );
    }

    this.loading = false;
  }

  public async fetchData(): Promise<Order> {
    const order = await this.orderService.api.findOne({
      authentication_token: this.userService.getActiveToken(),
      id: this.id,
    });
    if (!order) throw Error('Unable to fetch existing order');
    return order;
  }

  protected async fetchCollections(): Promise<Collection[]> {
    const { collections } = await this.collectionService.api.find({
      authentication_token: this.userService.getActiveToken(),
    });

    await this.collectionService.insert({ data: collections });

    return this.collectionService
      .query()
      .with('products')
      .whereIdIn(collections.map((c: any) => c.id))
      .get();
  }

  protected async onClickSave(order: Order) {
    try {
      const { comment } = await CommentPromptDialog.attach().apply(this);
      order.notes = comment;
      const success = await this.save(order);
      if (success) this.$genify.notify(`Successfully updated order #${order.id}!`, 'success');
      this.afterSave(success, order, { name: 'orders-list' });
    } catch (error) {
      if (error) this.logger.error(error);
    }
  }

  /**
   * Click handler for TableRowActions approve button
   */
  protected async onClickApprove() {
    const order = this.model;
    const success = await this.approve(order);
    this.afterSave(success, order, { name: 'orders-list' });
  }

  /**
   * Click handler for TableRowActions deny button
   */
  protected async onClickDeny() {
    const order = this.model;
    const success = await this.deny(order);
    this.afterSave(success, order, { name: 'orders-list' });
  }
}
