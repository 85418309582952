

























































































































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { FulfillmentCollectionView } from '@/components/Fulfillment/FulfillmentCollectionView.vue';
import { ITableView, PaginationOptions, PermitableTableHeader } from '@/lib/interfaces';
import { Logger } from '@/tools/Logger';
import { Order } from '@/models/internal';
import { OrderAbilities } from '@/lib/types';
import { OrderService, UserService } from '@/services';
import { OrdersListControls } from './components/OrdersListControls.vue';
import { OrderStatus } from '@/lib/enum/Status.enum';
import { Utility } from '@/tools/Utility';
import ApprovalConfirmationDialog from '@/components/Dialog/ApprovalConfirmationDialog.vue';
import ApprovalStatusIndicator from '@/components/StatusIndicator/ApprovalStatusIndicator.vue';
import OrderStatusIndicator from '@/components/StatusIndicator/OrderStatusIndicator.vue';
import OrderTypeIndicator from '@/components/StatusIndicator/OrderTypeIndicator.vue';
import prepareData from '@/models/functions/prepareData';
import TableRowActions from '@/components/Table/TableRowActions.vue';
import { Dictionary } from 'vue-router/types/router';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';
import { IUpdateContextBar } from '@/components/ContextBar/interfaces/UpdateContextBar.interface';

@Component({
  name: 'OrdersList',
  components: {
    ApprovalStatusIndicator,
    OrderTypeIndicator,
    OrderStatusIndicator,
    TableRowActions,
    ApprovalConfirmationDialog,
    OrdersListControls,
    FulfillmentCollectionView,
  },
})
export default class OrdersList extends Vue implements ITableView<Order>, IUpdateContextBar {
  @Prop({ required: true })
  protected readonly canCreate!: () => boolean;

  @Prop({ required: true })
  protected readonly canRead!: () => boolean;

  @Prop({ required: true })
  protected readonly canUpdate!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly canUpdateStatus!: (order: Order) => boolean;

  @Prop({ required: true })
  protected readonly edit!: (order: Order) => void;

  @Prop({ required: true })
  protected readonly view!: (order: Order) => void;

  @Prop({ required: true })
  protected readonly approve!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly deny!: (order: Order) => Promise<any>;

  @Prop({ required: true })
  protected readonly downloadPdf!: (id: number) => Promise<void>;

  public selected: Order[] = [];

  public data: Order[] = [];

  public headers: PermitableTableHeader[] = [
    {
      text: 'Order ID',
      value: 'id',
      width: 100,
      sortable: true,
    },
    {
      text: 'Site Name',
      value: 'bill_address.facility_name',
      width: 360,
      sortable: true,
    },
    {
      text: 'Created At',
      value: 'created_at',
      width: 115,
      sortable: true,
    },
    {
      text: 'Updated At',
      value: 'updated_at',
      width: 115,
      sortable: true,
    },
    {
      text: 'Type',
      value: 'order_detail.priority',
      width: 80,
      sortable: true,
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false,
    },
    {
      text: 'Approvals',
      value: 'approval_status',
      ability: [
        ['manage_part_a', Order],
        ['manage_part_b', Order],
        ['manage_part_c', Order],
      ],
      sortable: false,
    },
    {
      text: '',
      value: 'comments',
      width: 0,
      sortable: false,
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false,
    },
  ];

  protected readonly orderService: OrderService = OrderService.getInstance();

  protected readonly userService: UserService = UserService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'OrdersList' });

  protected readonly renderConfirmation = ApprovalConfirmationDialog.attach.bind(this);

  protected actions = [];

  protected loading = false;

  protected formatDate = Utility.formatDate;

  protected highlightSearchText = Utility.highlightSearchText;

  protected search = '';

  protected allItemsSelected = false;

  protected onlyShowRequireApproval = false;

  protected expanded = [];

  protected options: PaginationOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['updated_at'],
    sortDesc: [true],
  };

  protected totalRecords: number | string = -1;

  /**
   * Debounced init function (used for limiting fetches from the search field)
   */
  private debouncedInit = debounce(this.init, 500);

  @Watch('options', { deep: true, immediate: false })
  protected watchPagination() {
    this.loading = true;
    this.debouncedInit();
  }

  @Watch('search', { immediate: false })
  protected watchSearch(search: string) {
    this.loading = true;
    this.selected = [];
    if (this.$route.query.search !== search) {
      this.options.page = 1;
    }
    this.debouncedInit();
  }

  @Watch('onlyShowRequireApproval')
  protected watchOnlyShowRequireApproval() {
    this.loading = true;
    this.init();
  }

  @Watch('loading', { immediate: false })
  protected watchLoading(loading: boolean) {
    if (!loading) this.afterLoading();
  }

  public mounted() {
    this.initPaginationOptions();
    this.filterHeaders();
    this.debouncedInit();
    this.updateContextBar();
  }

  public afterLoading() {
    this.updateContextBar();
  }

  public updateContextBar() {
    if (this.canCreate()) {
      ContextBarManager.setActions({
        icon: 'mdi-plus-outline',
        color: 'success',
        label: 'Create Order',
        to: { name: 'orders-setup' },
      });
    }
  }

  public async init(): Promise<void> {
    this.loading = true;

    await this.setPagination(this.options);

    try {
      const data = await this.fetchData();
      const preparedData = prepareData(data, OrderService.mapData);
      const orders = preparedData.map((datum: any) => new Order(datum));
      this.data = orders;
    } catch (error) {
      this.logger.error(error);
    } finally {
      this.loading = false;
    }
  }

  public async fetchData(): Promise<any> {
    const params: any = {
      authentication_token: this.userService.getActiveToken(),
      ...this.getPagination(this.$route.query),
    };

    if (this.onlyShowRequireApproval) {
      params.require_approval_only = true;
    }

    const { draft_orders, total } = await this.orderService.api.find(params);
    this.totalRecords = total || 0;

    if (!draft_orders) throw Error('Unable to fetch a list of Orders');

    return draft_orders;
  }

  /**
   * Initialize pagination params for the first time - apply defaults, etc.
   */
  protected initPaginationOptions(): void {
    const params = this.getPagination(this.$route.query);

    if (!params.sort) params.sort = 'updated_at:desc';
    const sortDesc: boolean[] = [];
    const sortBy = params.sort.split(',').map((s) => {
      const [field, direction] = s.split(':');
      if (!direction) {
        sortDesc.push(true); // Default to `desc` if nothing provided
      } else {
        sortDesc.push(/^desc$/g.test(direction)); // Otherwise test if it's `desc`
      }
      return field;
    });

    this.options.page = (!params.page) ? 1 : parseInt(params.page);
    this.options.itemsPerPage = (!params.per_page) ? 10 : parseInt(String(params.per_page));
    this.options.sortBy = (!sortBy) ? ['updated_at'] : sortBy;
    this.options.sortDesc = (!sortDesc) ? [true] : sortDesc;

    if (params.search && params.search !== this.search) {
      this.search = params.search;
    }
  }

  /**
   * Set pagination parameters in the URL's querystring.
   */
  protected setPagination(options: PaginationOptions) {
    const sortBy = options.sortBy.reduce((acc, curr, idx, arr) => {
      const sortDirection = (!options.sortDesc[idx]) ? 'asc' : 'desc';
      const sortString = acc + `${curr}:${sortDirection}`;
      return (idx < arr.length - 1) ? sortString + ',' : sortString;
    }, '') || undefined;

    const location: any = {
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: options.page?.toString(),
        per_page: options.itemsPerPage?.toString(),
      },
    };

    if (this.search) {
      location.query.search = this.search;
    } else {
      delete location.query.search;
    }

    if (sortBy) {
      location.query.sort = sortBy;
    }

    return this.$router.push(location).catch(() => {/* ignore */});
  }

  /**
   * Get the pagination parameters from the URL's querystring.
   */
  protected getPagination(query: Dictionary<string | (string | null)[]>) {
    return {
      page: query.page?.toString(),
      per_page: query.per_page?.toString(),
      search: query.search?.toString(),
      sort: query.sort?.toString(),
    };
  }

  /**
   * In the Orders visible in the table, select all that have
   * a `submitted` status, meaning they're awaiting to be reviewed
   */
  protected selectSubmitted(orders: Order[]) {
    this.selected = orders.filter(
      (order) => order.status === OrderStatus.Submitted,
    );
  }

  /**
   * Filter out any table headers this User cannot see or interact
   * with
   */
  protected filterHeaders() {
    this.headers = this.headers.filter((header) => {
      if (!header.ability || !Array.isArray(header.ability)) return true;
      const abilities = header.ability as OrderAbilities[];
      return abilities.some((ability: OrderAbilities) => this.$ability.can(ability[0], ability[1]));
    });
  }

  /**
   * Fetch data for a single Order record
   */
  protected async fetchOrder(id: number): Promise<Order> {
    const order = await this.orderService.api.findOne({
      authentication_token: this.userService.getActiveToken(),
      id,
    });
    if (!order) throw Error('Unable to fetch existing order');
    return order;
  }

  /**
   * Click handler for TableRowActions approve button
   */
  protected async onClickApprove(order: Order) {
    let _order;
    try {
      const data = await this.fetchOrder(order.id);
      const preparedData = prepareData(data, OrderService.mapData);

      _order = new Order(preparedData);
    } catch (error) {
      this.logger.error(error);
      return;
    }

    const success = await this.approve(_order);
    if (success) this.init();
  }

  /**
   * Click handler for TableRowActions deny button
   */
  protected async onClickDeny(order: Order) {
    let _order;
    try {
      const data = await this.fetchOrder(order.id);
      const preparedData = prepareData(data, OrderService.mapData);

      _order = new Order(preparedData);
    } catch (error) {
      this.logger.error(error);
      return;
    }
    const success = await this.deny(_order);
    if (success) this.init();
  }

  /**
   * Process event(s) from sibling/child components, like table controls
   * and pagination, etc.
   */
  protected async onAction(action: string) {
    this.logger.info(`Action "${action}" fired`);

    switch (action) {
      case 'reload': {
        this.loading = true;
        this.debouncedInit();
        break;
      }
      default: {
        this.logger.warn(`Action "${action}" is not implemented in ${this.constructor}`);
      }
    }
  }
}
