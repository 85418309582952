














































import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'SearchFieldTooltip',
})
export default class SearchFieldTooltip extends Vue {

}
