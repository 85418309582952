
























import { Vue, Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { Logger } from '@/tools/Logger';
import { ApprovalStatus } from '@/lib/enum/Status.enum';
import { Order, OrderItem } from '@/models/internal';
import { OrderService, UserService } from '@/services';
import CommentPromptDialog from '@/components/Comments/components/CommentPromptDialog.vue';
import FileSaver from 'file-saver';
import ApprovalConfirmationDialog from '@/components/Dialog/ApprovalConfirmationDialog.vue';
import { Page } from '@/components/Page.vue';
import { RawLocation } from 'vue-router';

type CanUpdateStatusActions = 'review'|'approve'|'approve_part_a'|'approve_part_b'|'approve_part_c';

@Component({
  name: 'Orders',
  components: {
    Page,
    ApprovalConfirmationDialog,
  },
})
export default class Orders extends Vue {
  @Sync('context/overlay@visible')
  protected isOverlayVisible!: boolean;

  @Sync('context/overlay@visible')
  protected loading!: boolean;

  protected readonly orderService: OrderService = OrderService.getInstance();

  protected readonly userService: UserService = UserService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'Orders' });

  /**
   * Navigate to the Order edit form
   */
  protected edit(order: Order) {
    this.$router.push({
      name: 'orders-edit',
      params: {
        id: String(order.id),
      },
    });
  }

  /**
   * Navigate to a static summary of the Order
   */
  protected view(order: Order): void {
    this.$router.push({
      name: 'orders-view',
      params: {
        id: String(order.id),
      },
    });
  }

  /**
   * Check if User can create a new Order
   */
  protected canCreate() {
    return this.$ability.can('create', Order);
  }

  /**
   * Check if User can view an Order
   */
  protected canRead() {
    return this.$ability.can('read', Order);
  }

  /**
   * User can update a Order record
   */
  protected canUpdate(order: Order): boolean {
    if (order?.id) {
      return this.$ability.can('update', order);
    }
    return false;
  }

  /**
   * Check if this user has permission to an approval action.
   * If no action is specified, checks all approval actions.
   *
   * @param {string} [action]  Which form action they can perform
   * @returns {boolean} Can approve one or any action
   */
  protected canUpdateStatus(
    order: Order,
    action?: CanUpdateStatusActions,
  ) {
    if (order?.id) {
      if (action) {
        return this.$ability.can(action, order);
      }
      return (
        this.$ability.can('review', order)
        || this.$ability.can('approve', order)
        || this.$ability.can('approve_part_a', order)
        || this.$ability.can('approve_part_b', order)
        || this.$ability.can('approve_part_c', order)
      );
    }
    return false;
  }

  /**
   * Approve Order
   */
  protected async approve(order: Order) {
    if (this.$ability.can('review', order)) {
      order.approvals.info = ApprovalStatus.Reviewed;
    }
    if (this.$ability.can('approve', order)) {
      order.approvals.info = ApprovalStatus.Approved;
    }

    try {
      const { sections, comment } = await ApprovalConfirmationDialog.attach.apply(this, [{
        title: 'Approve',
        subtitle: 'Select the form-sections you would like to approve for this order',
        order,
      }]);

      sections.forEach((section: string) => {
        order.approvals[section] = ApprovalStatus.Approved;
      });

      order.notes = comment;

      const success = await this.save(order);
      if (success) this.$genify.notify(`Approved order #${order.id}!`, 'success');
      return success;
    } catch (error) {
      if (error) this.logger.error(error);
    }
  }

  /**
   * Deny Order
   */
  protected async deny(order: Order) {
    // Because we are denying this Order (so that it may not continue through
    // our approvals pipeline), we're going to explicitly set all of the
    // sections to `denied`, only getting a comment from the user for the timeline.
    if (this.$ability.can('approve', order) || this.$ability.can('review', order)) {
      order.notes = await this.promptForComment();
      ['info', 'part_a', 'part_b', 'part_c'].forEach((section) => {
        order.approvals[section] = ApprovalStatus.Denied;
      });
      return this.save(order);
    }

    try {
      const { sections, comment } = await ApprovalConfirmationDialog.attach.apply(this, [{
        title: 'Deny',
        subtitle: 'Select the form-sections you would like to deny for this order',
        order,
      }]);

      sections.forEach((section: string) => {
        order.approvals[section] = ApprovalStatus.Denied;
      });

      order.notes = comment;

      const success = await this.save(order);
      if (success) this.$genify.notify(`Denied order #${order.id}!`, 'warning');
      return success;
    } catch (error) {
      if (error) this.logger.error(error);
    }
  }

  /**
   * Prompts the user for order comments
   */
  protected async promptForComment(): Promise<string> {
    const { comment } = await CommentPromptDialog.attach().apply(this);
    return comment;
  }

  /**
   * Save Order changes to the server
   */
  protected async save(order: Order): Promise<any> {
    const isValid = await this.validateItems(order.order_items);
    if (!isValid) return;

    const token = this.userService.getActiveToken();
    if (!token) throw Error('Unable to get token from active User');

    this.isOverlayVisible = true;

    order.order_detail.date = new Date().toISOString();

    const params = {
      id: order.id,
      authentication_token: token,
      draft_order: order as any,
    };

    // Remap this bullshit back before sending. Lame as fuck
    params.draft_order.order_items.forEach((order_item: any) => {
      order_item.sku = order_item.product.sku;
      order_item.category = order_item.product.meta.category;
      order_item.peoplesoft = order_item.product.meta.peoplesoft;
      order_item.meditech = order_item.product.meta.meditech;
      order_item.max_quantity = order_item.product.meta.max_quantity;
      order_item.unit_of_measure_type = order_item.product.meta.unit_of_measure_type;
      order_item.unit_of_measure_size = order_item.product.meta.unit_of_measure_size;
      order_item.description = order_item.product.meta.description;
    });

    try {
      // remove previous form states to reduece request payload size
      const { data } = await this.orderService.api.update(params);
      const response = await this.orderService.insert({
        data,
        insertOrUpdate: ['users'],
      });
      return response;
    } catch (error) {
      this.logger.error(error);
      this.$genify.alert(
        `Sorry, there was an error while saving Order #${order.id}`,
        'error',
        {
          prominent: true,
          transition: 'scale-transition',
        });
    } finally {
      this.isOverlayVisible = false;
    }
  }

  protected afterSave(result: any, order: Order, location?: RawLocation) {
    if (!result) return;
    if (location) {
      this.$router.push(location);
    } else {
      this.$router.push({ name: 'orders-view', params: { id: String(order.id) } });
    }
  }

  /**
   * Validate order items to ensure quantities are valid
   */
  protected async validateItems(items: OrderItem[]): Promise<boolean> {
    let valid = true;
    const messages = [];
    const quantities = items.map((item) => {
      // Cleanse
      if (isNaN(parseInt(item.quantity.toString()))) {
        item.quantity = 0;
      }

      return item.quantity;
    });

    // Check for empty quantities
    const isEmpty = quantities.every((quantity) => quantity <= 0);
    if (isEmpty) {
      messages.push('Unable to place or save an Order without ordering at least one item');
      valid = false;
    }

    // Check for negative quantities
    const hasNegative = quantities.some((quantity) => quantity < 0);
    if (hasNegative) {
      messages.push('Unable to place or save an Order with negative quantities in any items');
      valid = false;
    }

    if (!valid) {
      this.$genify.alert(messages, 'error', {
        prominent: true,
        transition: 'scale-transition',
      });
    }
    return valid;
  }

  /**
   * Get the non-authorized sections of the form
   */
  protected getHiddenSections(order: Order) {
    const hiddenSections = [];
    const canManagePartA = this.$ability.can('manage_part_a', order);
    const canManagePartB = this.$ability.can('manage_part_b', order);
    const canManagePartC = this.$ability.can('manage_part_c', order);
    if (!canManagePartA) hiddenSections.push('part_a');
    if (!canManagePartB) hiddenSections.push('part_b');
    if (!canManagePartC) hiddenSections.push('part_c');
    return hiddenSections;
  }

  /**
   * Check if an Order has an item-count in a given section from section_counts field.
   */
  protected hasItemsInSectionCounts(section: string, order: Order) {
    const sc = order.section_counts.find((sc) => sc.name === section);
    const section_total = sc?.total_units ?? 0;
    return section_total > 0;
  }

  /**
   * Download Pdf of the order
   */
  protected async downloadPdf(id: number) {
    this.loading = true;
    try {
      const authenticationToken = this.userService.getActiveToken();
      const response = await this.orderService.api.download({
        authentication_token: authenticationToken,
        id,
      });
      FileSaver.saveAs(response, `order_${id}.pdf`);
    } catch (error) {
      this.logger.error(error);
    }
    this.loading = false;
  }
}
